import search from "../../img/icon/search.svg";
import clippedLogo from "../../img/clippedLogo.webp";

const DataAnalysis = () => {
  return (
    <div className="lg:w-1/2 p-8 flex flex-col items-center text-center bg-[#F5F5F5]/50 rounded-3xl">
      <div className="w-full py-4 flex">
        <div className="p-2 flex justify-center items-center bg-gradient-to-br from-[#B3BBEA] to-[#E9ECFF]/10 rounded-3xl">
          <img
            className="h-40 w-40"
            src={search}
            alt=""
          />
        </div>
      </div>
      <div className="w-2/3 py-4">
        <h1 className="font-bold text-3xl">Data Analysis</h1>
        <p className="my-2 py-2 border-[#AEACB5] border-t border-b">
          <span className="font-bold">
            Coming soon.
          </span>
        </p>
      </div>
      <div className="w-full flex justify-end">
        <img
          className="w-20"
          src={clippedLogo}
          alt=""
        />
      </div>
    </div>
  );
}

export default DataAnalysis;
