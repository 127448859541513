import virtualReality from "../../img/icon/virtualReality.svg";
import clippedLogo from "../../img/clippedLogo.webp";

const METAVerse = () => {
  return (
    <div className="lg:w-1/2 p-8 flex flex-col items-center text-center bg-[#F5F5F5]/50 rounded-3xl">
      <div className="w-full py-4 flex">
        <div className="p-2 flex justify-center items-center bg-gradient-to-br from-[#FF96B5] to-[#FDFDFD]/30 rounded-3xl">
          <img
            className="h-40 w-40"
            src={virtualReality}
            alt=""
          />
        </div>
      </div>
      <div className="w-2/3 py-4">
        <h1 className="font-bold text-3xl">META-Verse</h1>
        <p className="my-2 py-2 border-[#AEACB5] border-t border-b">
          We Cater events in the Metaverse.
          <br /><br />
          Bring your Brand to the Web3.0-Market,
          <br />
          <span className="font-bold">
            Or your Company.
          </span>
        </p>
      </div>
      <div className="w-2/3 py-4 flex flex-col">
        <h1 className="font-bold text-3xl">Specs:</h1>
        <ul className="py-4 text-[#757171] text-sm space-y-1">
          <li>3D Modelling by Architectural Design.</li>
          <li>Decentreland-import ready.</li>
          <li>Auto-Dance Zones feature.</li>
          <li>Lazy Loading feature.</li>
          <li>Payment Integration.</li>
          <li>Video Streaming.</li>
          <li>NFT Scanner.</li>
          <li>POAP Booth.</li>
          <li>Easter Eggs.</li>
          <br />
          <li>Paid-Button.</li>
          <li>Donation Box.</li>
          <li>NFT Wall-of-Fame.</li>
          <li>Wearables Scanner.</li>
          <li>Texture Finishings in HQ.</li>
          <li>Souvenir-NFT in Mystery Room.</li>
        </ul>
        <div className="mt-16 mb-4">
          <a href="/" className="px-10 py-4 border-black border-2 rounded-full hover:bg-black hover:text-white transition-all">Get Started</a>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <img
          className="w-20"
          src={clippedLogo}
          alt=""
        />
      </div>
    </div>
  );
}

export default METAVerse;
