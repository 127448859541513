import science from "../../img/icon/science.svg";
import clippedLogo from "../../img/clippedLogo.webp";

const ResearchAndDevelopmentPopOut = () => {
  return (
    <div className="lg:w-1/2 p-8 flex flex-col items-center text-center bg-[#F5F5F5]/50 rounded-3xl">
      <div className="w-full py-4 flex">
        <div className="p-2 flex justify-center items-center bg-gradient-to-br from-[#C2F5D7]/50 to-[#F9FFFB]/30 rounded-3xl">
          <img
            className="h-40 w-40"
            src={science}
            alt=""
          />
        </div>
      </div>
      <div className="w-2/3 py-4">
        <h1 className="font-bold text-3xl">Research & Development</h1>
        <p className="my-2 py-2 border-[#AEACB5] border-t border-b">
          Welcome.
          <br /><br />
          We'll bring to Life, the Landing of your Dreams,
          <br />
          <span className="font-bold">
            Blockchain-Integrated.
          </span>
        </p>
      </div>
      <div className="w-2/3 py-4 flex flex-col">
        <h1 className="font-bold text-3xl">Specs:</h1>
        <ul className="py-4 text-[#757171] text-sm space-y-1">
          <li>Secure Web Page (Padlock on-page, for user Security).</li>
          <li>Blockchain-integration (Creation of Smart Contracts).</li>
          <li>Payment Integration (Metamask Wallet).</li>
          <li>Design-to-code Adjustment.</li>
          <li>Smart Contracts (ERC-721).</li>
          <li>Page Functionality.</li>
          <li>Mint-Button.</li>
          <li>UX/UI.</li>
        </ul>
        <div className="mt-16 mb-4">
          <a href="/" className="px-10 py-4 border-black border-2 rounded-full hover:bg-black hover:text-white transition-all">Get Started</a>
        </div>
      </div>
      <div className="w-full flex justify-end">
        <img
          className="w-20"
          src={clippedLogo}
          alt=""
        />
      </div>
    </div>
  );
}

export default ResearchAndDevelopmentPopOut;
