import { Tween } from "react-gsap";

import mail from "../img/icon/mail.svg";
import linkedin from "../img/icon/linkedin.svg";

const NavBar = () => {
  return (
    <Tween
      to={{
        backgroundColor: "rgb(255,255,255)",
        color: "rgb(0,0,0)",
        padding: "0.5rem",
        scrollTrigger: {
          trigger: ".main",
          start: "100px top",
          scrub: 1
        }
      }}
    >
      <div className="fixed w-full z-50 p-6 flex justify-between text-white">
        <div className="flex justify-center items-center divide-[#F5F5F5]/70 divide-x-2">
          <div className="pr-2">ES</div>
          <div className="pl-2">EN</div>
        </div>
        <div className="hidden lg:flex space-x-4">
          <a href="mailto:chainsolutions.bc@gmail.com" target="_blank" rel="noreferrer">
            <div className="p-2 h-8 w-8 flex justify-center items-center bg-white rounded-full">
              <img
                src={mail}
                alt="Mandar un correo a chainsolutions.bc@gmail.com"
              />
            </div>
          </a>
          <a href="https://www.linkedin.com/company/chain-solutions-io/" target="_blank" rel="noreferrer">
            <div className="p-1 overflow-clip h-8 w-8 flex justify-center items-center bg-white rounded-full">
              <img
                src={linkedin}
                alt="Visitar LinkedIn"
              />
            </div>
          </a>
        </div>
      </div>
    </Tween>
  );
}

export default NavBar;
