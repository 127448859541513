import Hls from "hls.js";
import { useEffect, useRef } from "react";

function HLSVideo({ src, setVideoLoaded, ...attributes }) {
  const videoRef = useRef();

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();

      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
    }
  }, [src]);


  return <video ref={videoRef} onLoadedData={() => setVideoLoaded(true)} {...attributes} />
}

export default HLSVideo;
