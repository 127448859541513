import { useState } from "react";

import science from "../../img/icon/science.svg";
import virtualReality from "../../img/icon/virtualReality.svg";
import search from "../../img/icon/search.svg";
import nft from "../../img/icon/nft.svg";

import PopOutContainer from "../PopOuts/PopOutContainer";
import ResearchAndDevelopmentPopOut from "../PopOuts/ResearchAndDevelopmentPopOut";
import METAVerse from "../PopOuts/METAVerse";
import DataAnalysis from "../PopOuts/DataAnalysis";
import ArtCollectionDevelopment from "../PopOuts/ArtCollectionDevelopment";

function ResearchAndDevelopment() {
  const [currentPopOut, setCurrentPopOut] = useState(1);

  const handlePopOut = (id) => {
    if (currentPopOut === id) return;

    setCurrentPopOut(id)
  }

  return (
    <>
      <div id="start" className="p-6 flex justify-evenly space-x-6">
        <div className="p-2 flex-1 flex justify-center items-center bg-[#F5F5F5]/50 rounded-lg cursor-pointer hover:shadow-lg transition-all" onClick={() => handlePopOut(1)}>
          <div className="p-2 h-10 w-10 flex justify-center items-center bg-gradient-to-br from-[#C2F5D7]/50 to-[#F9FFFB]/30 rounded-lg animate-bounce">
            <img
              src={science}
              alt=""
            />
          </div>
          <div className="hidden lg:block flex-grow text-center">
            <h1 className="font-bold">Research & Development</h1>
          </div>
        </div>

        <div className="p-2 flex-1 flex justify-center items-center bg-[#F5F5F5]/50 rounded-lg cursor-pointer hover:shadow-lg transition-all" onClick={() => handlePopOut(2)}>
          <div className="p-2 h-10 w-10 flex justify-center items-center bg-gradient-to-br from-[#FF96B5] to-[#FDFDFD]/30 rounded-lg animate-bounce">
            <img
              src={virtualReality}
              alt=""
            />
          </div>
          <div className="hidden lg:flex flex-grow flex-col justify-center items-center">
            <h1 className="font-bold">META-Verse</h1>
            <p className="text-sm">VR experiences</p>
          </div>
        </div>

        <div className="p-2 flex-1 flex justify-center items-center bg-[#F5F5F5]/50 rounded-lg cursor-pointer hover:shadow-lg transition-all" onClick={() => handlePopOut(3)}>
          <div className="p-2 h-10 w-10 flex justify-center items-center bg-gradient-to-br from-[#B3BBEA] to-[#E9ECFF]/10 rounded-lg animate-bounce">
            <img
              src={search}
              alt=""
            />
          </div>
          <div className="hidden lg:block flex-grow text-center">
            <h1 className="font-bold">Data Analysis</h1>
          </div>
        </div>

        <div className="p-2 flex-1 flex justify-center items-center bg-[#F5F5F5]/50 rounded-lg cursor-pointer hover:shadow-lg transition-all" onClick={() => handlePopOut(4)}>
          <div className="p-2 h-10 w-10 flex justify-center items-center bg-gradient-to-br from-[#E7FF00] to-[#FEFFF4]/10 rounded-lg animate-bounce">
            <img
              src={nft}
              alt=""
            />
          </div>
          <div className="hidden lg:flex flex-grow flex-col justify-center items-center">
            <h1 className="font-bold">Art-Collection Development</h1>
            <p className="text-sm">NFT appraisal</p>
          </div>
        </div>
      </div>

      <div className="p-6 hidden lg:flex justify-start items-start">
        <div className="flex flex-col justify-center items-center divide-black divide-y-2">
          <div>02</div>
          <div>07</div>
        </div>
      </div>

      <PopOutContainer current={currentPopOut}>
        <ResearchAndDevelopmentPopOut />
        <METAVerse />
        <DataAnalysis />
        <ArtCollectionDevelopment />
      </PopOutContainer>
    </>
  );
}

export default ResearchAndDevelopment;
