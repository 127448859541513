import hikeMountains from "../../img/hike/mountains.webp";
import hikeHikers from "../../img/hike/hikers.webp";
import hikeClouds1 from "../../img/hike/clouds1.webp";
import hikeClouds2 from "../../img/hike/clouds2.webp";
import logo from "../../img/hike/logo.webp";

const GoToNewHeights = () => {
  return (
    <div className="flex flex-col justify-center items-center space-y-4">
      <h1 className="font-bold text-xl lg:text-3xl">Go to new heights, lenghts, depths.</h1>
      <a href="/" className="px-4 py-2 text-sm lg:text-base border border-black rounded-full hover:bg-black hover:text-white transition-all">
        Discover More
      </a>
      <div className="grid w-full bg-gradient-to-b from-white to-[#F5F5F7]">
        <img
          className="col-span-full row-span-full"
          src={hikeMountains}
          height={680}
          alt=""
        />

        <img
          className="col-span-full row-span-full"
          src={hikeHikers}
          height={680}
          alt=""
        />

        <img
          className="col-span-full row-span-full"
          src={hikeClouds1}
          alt=""
        />
        <img
          className="col-span-full row-span-full"
          src={hikeClouds2}
          alt=""
        />

        <img
          className="col-span-full row-span-full"
          src={logo}
          alt=""
        />
      </div>
    </div>
  );
}

export default GoToNewHeights;
