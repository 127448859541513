import { useState } from "react";

import HLSVideo from "../HLSVideo";
import useMobile from "../../hooks/useMobile";

import logoWhite from "../../img/logoWhite.webp";
import background from "../../img/background.webp";

import down from "../../img/icon/down.svg";
import wavesPlaceholder from "../../img/waves.webp";
import wavesVerticalPlaceholder from "../../img/waves-vertical.webp";
import circles from "../../img/icon/circles.svg";
import circlesVertical from "../../img/icon/circles-vertical.svg";

const FirstSectionWithWaves = () => {
  const isMobile = useMobile();
  const [isVideoLoaded, setVideoLoaded] = useState(false);

  return (
    <div className="main relative h-screen w-full shadow-lg">
      <img
        className="absolute h-full w-full object-cover"
        src={background}
        alt=""
      />
      <div className="absolute h-screen w-full p-6 flex flex-col text-[#F5F5F5]/70 bg-[#060004]/30">
        <div className="flex-grow flex flex-col items-center justify-between">
          <img
            className="py-12"
            src={logoWhite}
            alt="Chain Solutions logo"
            height={50}
            width={170}
          />

          <div className="pb-16 relative w-[230px] h-[402px] lg:w-[460px] lg:h-[262px]">
            <img
              className="absolute top-0 right-0 w-[460px]"
              src={isMobile ? circlesVertical : circles}
              alt=""
            />

            {!isVideoLoaded ? (
              <img
                className="absolute top-0 right-0 w-[460px]"
                width={1000}
                src={isMobile ? wavesVerticalPlaceholder : wavesPlaceholder}
                alt=""
              />
            ) : ""}

            <HLSVideo
              src={isMobile ? "./video/waves-vertical/waves-vertical.m3u8" : "./video/waves/waves.m3u8"}
              className="mix-blend-screen"
              width={isMobile ? "230px" : "460px"}
              setVideoLoaded={setVideoLoaded}
              autoPlay
              loop
              muted
              playsInline
            />
          </div>

          <a href="#start">
            <div className="flex">
              Scroll
              <img
                className="px-1 animate-bounce"
                alt="Ir abajo"
                src={down}
                height={15}
                width={21}
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FirstSectionWithWaves;
