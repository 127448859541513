import AguitadeLimon from "../../img/brands/AguitadeLimon.webp";
import BearLab from "../../img/brands/BearLab.webp";
import Desktware from "../../img/brands/Desktware.webp";
import MoyMulo from "../../img/brands/MoyMulo.webp";

const JointheAdventure = () => {
  return (
    <div className="hidden lg:flex flex-col justify-center items-center">
      <h1 className="pb-4 font-bold text-xl">Join the Adventure.</h1>
      <div className="w-full p-6 flex justify-between items-center bg-black space-x-20">
        <a href="https://aguitadelimon.com" target="_blank" rel="noreferrer">
          <img
            src={AguitadeLimon}
            alt="Agüita de Limón logo"
          />
        </a>
        <img
          src={MoyMulo}
          alt="MoyMulo logo"
        />
        <a href="https://bearlab.club" target="_blank" rel="noreferrer">
          <img
            src={BearLab}
            alt="BearLab logo"
          />
        </a>
        <img
          src={Desktware}
          alt="Desktware logo"
        />
      </div>
    </div >
  );
}

export default JointheAdventure;
