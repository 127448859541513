import { Tween } from "react-gsap";

import link from "../../img/link.webp";

const SmartSolutions = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="p-16">
        <Tween
          stagger={1}
          from={{
            x: -500,
            opacity: 0
          }}
          to={{
            x: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: ".sec1",
              scrub: 1,
              start: "center bottom",
              end: "bottom center"
            }
          }}
        >
          <h1 className="sec1 pb-4 text-4xl lg:text-7xl font-bold border-b border-[#AEACB5]/50">
            Smart solutions.
            <br />
            <span className="font-normal">for</span>
            <br />
            Secure
            <br />
            <span className="flex">Contracts.
              <img
                className="mx-4"
                src={link}
                height={62}
                width={62}
                alt=""
              />
            </span>
          </h1>

          <p className="pt-4 text-[#AEACB5]">
            Say welcome to the Future.
            <br />
            Chain Technologies are here to optimise your day-to-day bureaucratic burdens.
          </p>
        </Tween>
      </div>

      <a href="/" className="my-16 px-4 py-2 border-black border-2 rounded-full hover:bg-black hover:text-white transition-all">Find out Why</a>
    </div>
  );
}

export default SmartSolutions;
