import { Tween } from "react-gsap";

import digitizer from "../../img/icon/digitizer.svg";

import logoWhite from "../../img/logoWhite.webp";
import desktop from "../../img/desktop.webp";

const Cryptastic = () => {
  return (
    <div className="relative w-full bg-black">
      <div className="lg:hidden relative flex justify-center items-center bg-white">
        <Tween
          from={{
            rotation: 0
          }}

          to={{
            rotation: 360,
            scrollTrigger: {
              trigger: ".digitizer",
              scrub: 1
            }
          }}
        >
          <img
            className="digitizer z-10 w-2/3"
            src={digitizer}
            alt=""
          />
        </Tween>
        <span className="absolute bottom-0 h-1/2 w-full bg-black"></span>
      </div>

      <div className="py-8 lg:absolute lg:top-[10%] lg:right-[15%] flex flex-col justify-center items-center text-center space-y-4">
        <h1 className="font-bold text-3xl text-white">Cryptastic.</h1>
        <p className="text-[#AEACB5]">
          How does it work?
          <br />
          Click below to MINT a Test NFT.
        </p>
        {/*<MintButton />*/}
      </div>

      <img
        src={desktop}
        alt=""
      />
      <img
        className="hidden lg:block absolute bottom-[5%] left-[5%]"
        src={logoWhite}
        alt="Chain Solutions logo"
        height={50}
        width={170}
      />
    </div>
  );
}

export default Cryptastic;
