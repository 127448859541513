const PopOutContainer = ({ children, current }) => {
  // Current = 0, means no open popout.
  if (!current) return;

  return (
    <div className="flex justify-center items-center">
      {children[current - 1]}
    </div>
  );
}

export default PopOutContainer;
