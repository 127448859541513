import { useRef, useEffect } from "react";
import BlazeSlider from "blaze-slider";
import "blaze-slider/dist/blaze.css";

import laptop from "../../img/laptop.webp";
import phone from "../../img/phone.webp";
import script from "../../img/script.webp";
import world from "../../img/world.webp";

const OurTailoredTrifecta = () => {
  const blazeSliderRef = useRef();
  const carrouselRef = useRef();

  useEffect(() => {
    // Make sure to only initialize the slider once
    if (!blazeSliderRef.current) {
      blazeSliderRef.current = new BlazeSlider(carrouselRef.current, {
        all: {
          enableAutoplay: true,
          autoplayInterval: 10000,
          transitionDuration: 300,
          slidesToShow: 1,
        }
      });
    }
  }, []);

  return (
    <div className="my-8 flex flex-col justify-center items-center text-center">
      <h1 className="font-bold text-3xl">Our Tailored Trifecta.</h1>
      <p className="text-[#AEACB5]">Made-to-Measure bundles</p>
      <p className="my-4 text-2xl">
        🖥 + 🔧 + 🔗
      </p>

      <div className="blaze-slider" ref={carrouselRef}>
        <div className="blaze-container">
          <div className="blaze-track-container">
            <div className="blaze-track">
              <div className="flex flex-col justify-center items-center">
                <div className="relative">
                  <img
                    src={laptop}
                    height={500}
                    alt=""
                  />
                  <img
                    className="absolute top-0 right-0"
                    src={phone}
                    alt=""
                  />
                </div>

                <p className="my-8 text-[#AEACB5]">
                  Neat UI/UX design. Supercharged by intuitive architecture.
                  <br />
                  Light. Bright. Full of might.
                </p>
              </div>

              <div className="flex flex-col justify-center items-center">
                <img
                  src={script}
                  height={500}
                  alt=""
                />

                <p className="my-8 text-[#AEACB5]">
                  Our programmers will script like Shakespeare.
                  <br />
                  Small feat. Giant leap.
                </p>
              </div>

              <div className="flex flex-col justify-center items-center">
                <img
                  src={world}
                  height={500}
                  alt=""
                />

                <p className="my-8 text-[#AEACB5]">
                  Prepare for takeoff.
                  <br />
                  We&apos;ll power the Blockchain® module.
                </p>
              </div>
            </div>
          </div>

          <button className="blaze-prev absolute h-12 w-12 top-1/2 z-10 flex justify-center items-center text-xl bg-white border-2 border-dotted border-black rounded-full translate-y-[-60px]  translate-x-[60px] cursor-pointer hover:bg-black hover:text-white transition-all">
            &lt;
          </button>
          <button className="blaze-next absolute h-12 w-12 top-1/2 right-0 z-10 flex justify-center items-center text-xl bg-white border-2 border-dotted border-black rounded-full translate-y-[-60px]  translate-x-[-60px] cursor-pointer hover:bg-black hover:text-white transition-all">
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default OurTailoredTrifecta;
