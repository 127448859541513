import logoWhite from "../img/logoWhite.webp";

const Footer = () => {
  return (
    <footer className="py-12 p-6 flex justify-between bg-[#EDECEA]">
      <div className="basis-1/4">
        <img
          src={logoWhite}
          alt="Chain Solutions logo"
          height={50}
          width={170}
        />
      </div>
      <p className="basis-3/4 text-xs lg:text-sm text-right text-[#AEACB5]">
        Powered by Chain Solutions®.
        <br />
        Copyright © 2022 Chain Solutions. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
