import utility from "../styles/utility.module.css";

import mail from "../img/icon/mail.svg";
import telephone from "../img/icon/telephone.svg";
import msg from "../img/icon/msg.svg";

function ContactForm() {
  const handleSubmit = async (e) => {
    e.preventDefault();

    const firstName = e.target["first_name"].value;
    const lastName = e.target["last_name"].value;
    const email = e.target["email"].value;
    const posted = e.target["posted"].checked;

    const body = {
      firstName,
      lastName,
      email,
      posted
    };

    try {
      const response = await fetch("/api/submitContactForm", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });
      if (response.status !== 200) throw new Error(`The API returned status code: ${response.status}`);

      const json = await response.json();
      if (!json.success) throw new Error("The API returned no success");

      console.log("Form submitted!", json);
    } catch (error) {
      console.error("Something went wrong while submitting contact form.", error);
    }
  }

  return (
    <form
      className="py-8"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-2 gap-4">
        <input
          className="px-2 py-1 rounded-full shadow text-sm placeholder:text-[#3C3C43]/6"
          type="text"
          id="first_name"
          name="first_name"
          placeholder="First name"
          required
        />
        <input
          className="px-2 py-1 rounded-full shadow text-sm placeholder:text-[#3C3C43]/6"
          type="text"
          id="last_name"
          name="last_name"
          placeholder="Last name"
          required
        />
        <input
          className="px-2 py-1 rounded-full shadow text-sm placeholder:text-[#3C3C43]/6"
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          required
        />
      </div>

      <div className="py-8">
        <p className="text-[#AEACB5]">
          How would you like to be reached-out ?
        </p>
        <div className="py-2 flex justify-center items-center space-x-4">
          <a href="mailto:chainsolutions.bc@gmail.com" target="_blank" rel="noreferrer">
            <div className="p-2 h-8 w-8 flex justify-center items-center bg-white rounded-full">
              <img
                src={mail}
                alt="Mandar un correo a chainsolutions.bc@gmail.com"
              />
            </div>
          </a>

          <a href="tel:+525532581378" target="_blank" rel="noreferrer">
            <div className="p-2 h-8 w-8 flex justify-center items-center bg-white rounded-full">
              <img
                src={telephone}
                alt="Marcar por teléfono a +525532581378"
              />
            </div>
          </a>

          <a href="sms:+525532581378" target="_blank" rel="noreferrer">
            <div className="p-2 h-8 w-8 flex justify-center items-center bg-white rounded-full">
              <img
                src={msg}
                alt="Mandar mensaje a +525532581378"
              />
            </div>
          </a>
        </div>
      </div>

      <div className="flex justify-center items-center text-sm text-[#AEACB5] space-x-4">
        <span>Keep my posted.</span>
        <label className={utility.switch}>
          <input
            className={utility.checkbox}
            type="checkbox"
            id="posted"
            name="posted"
          />
          <span className={utility.slider}></span>
        </label>
      </div>

      <input
        className="my-4 px-8 py-2 border border-black rounded-full cursor-pointer hover:bg-black hover:text-white transition-all"
        type="submit"
        value="Get Started"
      />
    </form>
  );
}

export default ContactForm;
