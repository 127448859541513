import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

import mountains from "./img/mountains.webp";
import chip from "./img/chip.webp";
import code from "./img/code.webp";

import ContactForm from "./components/ContactForm";
import NavBar from "./components/NavBar";
import SmartSolutions from "./components/Sections/SmartSolutions";
import OurTailoredTrifecta from "./components/Sections/OurTailoredTrifecta";
import JointheAdventure from "./components/Sections/JointheAdventure";
import FirstSectionWithWaves from "./components/Sections/FirstSectionWithWaves";
import GoToNewHeights from "./components/Sections/GoToNewHeights";
import Footer from "./components/Footer";
import Cryptastic from "./components/Sections/Cryptastic";
import ResearchAndDevelopment from "./components/Sections/ResearchAndDevelopment";

gsap.registerPlugin(ScrollTrigger);

function App() {
  return (
    <>
      <NavBar />

      <FirstSectionWithWaves />

      <ResearchAndDevelopment />

      <SmartSolutions />

      <JointheAdventure />

      <div className="relative h-screen lg:my-16">
        <img
          className="absolute h-full w-full object-cover"
          src={mountains}
          alt=""
        />
        <div className="absolute w-screen p-8 flex flex-col justify-center items-center text-center">
          <h1 className="my-1 font-bold text-3xl">Trusted by Visionaries.</h1>
          <p className="text-[#AEACB5]">
            the power of the Blockchain® with the might of Mount Everest
          </p>
        </div>
      </div>

      <div className="my-16">
        <div className="p-6 hidden lg:flex justify-start items-start">
          <div className="flex flex-col justify-center items-center divide-black divide-y-2">
            <div>03</div>
            <div>07</div>
          </div>
        </div>
        <div className="p-6 flex flex-col justify-center items-center">
          <h1 className="my-1 font-bold text-2xl lg:text-3xl">We bridge Offline to Online.</h1>
          <div className="text-[#AEACB5] text-center">
            <p className="pb-4">
              Prepare for a full hands-on Digital Assets Governance.
            </p>
            <p className="border-[#AEACB5] border-t py-4">
              Surf Web3.0 like it&apos;s a charm.
            </p>
            <p>
              📑 🔜 🌐
            </p>
          </div>
        </div>
      </div>

      <Cryptastic />

      <div className="my-16 p-6 flex flex-col justify-center items-center text-center">
        <h1 className="font-bold text-3xl">ERC-721.</h1>
        <p className="text-[#AEACB5]">
          Smart-Contracts, democratized.
          <br />
          Plug your Enterprise to the NFTs-Ecosystem.
        </p>
        <img
          className="my-8"
          src={chip}
          alt=""
        />
        <p className="text-[#AEACB5]">
          Powered by Chain Solutions®. help your Business reach Global-Transaction capabilities.
          <br />
          Smart, Inmediate, Secure.
        </p>
      </div>

      <div className="relative h-[200px]">
        <img
          className="absolute h-full w-full object-cover"
          src={code}
          alt=""
        />
      </div>

      <OurTailoredTrifecta />

      <GoToNewHeights />

      <div className="p-6 flex flex-col justify-center items-center text-center bg-[#F5F5F7] text-sm lg:text-base">
        <h1 className="font-bold text-3xl">Nice to meet you.</h1>
        <p className="py-2 text-[#AEACB5] border-b border-[#AEACB5]/50">
          Get your questions answered by an expert via phone, chat or email.
          <br />
          While on-the-go, Chain-Support®. 🔗 has you covered.
        </p>

        <ContactForm />
      </div>

      <Footer />
    </>
  );
}

export default App;
